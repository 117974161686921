import * as React from "react";
import {
  Admin,
  ListGuesser,
  EditGuesser,
  Resource,
  fetchUtils,
  List,
} from "react-admin";
import { decode } from "jsonwebtoken";
import {
  DirectoryCreate,
  DirectoryList,
  DirectoryEdit,
} from "./Resources/Directory";
import {
  CalendarCreate,
  CalendarEdit,
  CalendarList,
} from "./Resources/Calendar";
import {
  MilestoneCreate,
  MilestoneEdit,
  MilestoneList,
} from "./Resources/Milestone";
import {
  InitiativeCreate,
  InitiativeList,
  InitiativeEdit,
} from "./Resources/Initiative";
import {
  CalendarCategoryCreate,
  CalendarCategoryEdit,
  CalendarCategoryList,
} from "./Resources/CalendarCategory";
import {
  AumBreakdownCreate,
  AumBreakdownList,
  AumBreakdownEdit,
} from "./Resources/AumBreakdown";
import jsonServerProvider from "ra-data-json-server";
import authProvider from "./Resources/auth/authProvider";
import { apiUrl } from "./config";
import { theme } from "./theme";

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const accessToken = JSON.parse(
    localStorage.getItem("iln_admin_access_token"),
  );
  const refreshToken = JSON.parse(
    localStorage.getItem("iln_admin_refresh_token"),
  );
  const decoded = decode(accessToken);

  const isTokenExpired = !(decoded?.exp * 1000 > new Date().getTime());

  if (isTokenExpired) {
    const request = new Request(apiUrl() + "/token-refresh/", {
      method: "POST",
      body: JSON.stringify({ access: accessToken, refresh: refreshToken }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `JWT ${accessToken}`,
      }),
    });
    await fetch(request)
      .then((response) => {
        if (response.status === 401) {
          // refresh token has expired, logout the user
          localStorage.removeItem("iln_admin_access_token");
          localStorage.removeItem("iln_admin_refresh_token");
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.removeItem("iln_admin_access_token");
        localStorage.removeItem("iln_admin_refresh_token");
        localStorage.setItem(
          "iln_admin_access_token",
          JSON.stringify(auth.access_token),
        );
        localStorage.setItem(
          "iln_admin_refresh_token",
          JSON.stringify(auth.refresh_token),
        );

        options.headers.set("Authorization", `JWT ${auth.access_token}`);
      })
      .catch(() => {
        throw new Error("Network error");
      });
  } else {
    options.headers.set("Authorization", `JWT ${accessToken}`);
  }

  return fetchUtils.fetchJson(url + "/", options);
};

const dataProvider = jsonServerProvider(
  `${apiUrl()}/ilndashboard/iln-admin`,
  httpClient,
);

const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <Resource
        name="directory"
        list={DirectoryList}
        edit={DirectoryEdit}
        create={DirectoryCreate}
      />
      <Resource
        name="aum-breakdown"
        list={AumBreakdownList}
        edit={AumBreakdownEdit}
        create={AumBreakdownCreate}
      />
      <Resource
        name="calendar"
        list={CalendarList}
        edit={CalendarEdit}
        create={CalendarCreate}
      />
      <Resource
        name="calendar-category"
        list={CalendarCategoryList}
        edit={CalendarCategoryEdit}
        create={CalendarCategoryCreate}
      />
      <Resource
        name="milestone"
        list={MilestoneList}
        edit={MilestoneEdit}
        create={MilestoneCreate}
      />
      <Resource
        name="initiative"
        list={InitiativeList}
        edit={InitiativeEdit}
        create={InitiativeCreate}
      />
    </Admin>
  );
};

export default App;
