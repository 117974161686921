import { apiUrl } from "../../config";
import { decode } from "jsonwebtoken";

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(apiUrl() + "/ilndashboard/iln-admin/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((auth) => {
        localStorage.setItem(
          "iln_admin_access_token",
          JSON.stringify(auth.access_token),
        );
        localStorage.setItem(
          "iln_admin_refresh_token",
          JSON.stringify(auth.refresh_token),
        );
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("iln_admin_access_token");
      localStorage.removeItem("iln_admin_refresh_token");
      return Promise.reject({ message: false });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async () => {
    return localStorage.getItem("iln_admin_access_token")
      ? Promise.resolve()
      : Promise.reject({ message: "login.required" });
  },
  logout: () => {
    localStorage.removeItem("iln_admin_access_token");
    localStorage.removeItem("iln_admin_refresh_token");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, fullName, avatar } = JSON.parse(
        localStorage.getItem("iln_admin_access_token"),
      );
      return Promise.resolve({ id, fullName, avatar });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
