import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  required,
} from "react-admin";
import { exportSanitizedData } from "../utils/csvSanitizedExport";
import { ColorField, ColorInput } from "react-admin-color-input";

const exporter = (rows) => {
  const rowsForExport = rows.map((post) => {
    const { id, deleted, date_deleted, ...rowsForExport } = post;
    return rowsForExport;
  });
  exportSanitizedData(
    rowsForExport,
    ["category_name", "color"],
    "iln-calendar-categories",
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const CalendarCategoryList = (props) => (
  <List {...props} exporter={exporter} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="category_name" />
      <ColorField source="color" picker="Twitter" />
    </Datagrid>
  </List>
);

export const CalendarCategoryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="category_name" validate={required()} />
      <ColorInput
        disabled
        source="color"
        picker="Twitter"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export const CalendarCategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="category_name" validate={required()} />
      <ColorInput source="color" picker="Twitter" validate={required()} />
    </SimpleForm>
  </Create>
);
