import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  EmailField,
  Filter,
  required,
} from "react-admin";
import { exportSanitizedData } from "../utils/csvSanitizedExport";

const exporter = (rows) => {
  const rowsForExport = rows.map((post) => {
    const { id, deleted, date_deleted, ...rowsForExport } = post;
    return rowsForExport;
  });
  exportSanitizedData(
    rowsForExport,
    ["name", "role", "member", "email", "committee"],
    "iln-directory",
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const DirectoryList = (props) => (
  <List {...props} exporter={exporter} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="role" />
      <TextField source="member" />
      <EmailField source="email" />
      <TextField source="committee" />
    </Datagrid>
  </List>
);

export const DirectoryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="role" validate={required()} />
      <TextInput source="member" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="committee" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const DirectoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="role" validate={required()} />
      <TextInput source="member" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="committee" validate={required()} />
    </SimpleForm>
  </Create>
);
