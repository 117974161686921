import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  useNotify,
  required,
} from "react-admin";
import { aumFailureMessage } from "../utils/onfailure";

export const AumBreakdownList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="company_name" />
      <TextField source="amount" />
    </Datagrid>
  </List>
);

export const AumBreakdownEdit = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(aumFailureMessage(error, "warning"));
  };
  return (
    <Edit onFailure={onFailure} mutationMode="pessimistic" {...props}>
      <SimpleForm>
        <TextInput source="company_name" validate={required()} />
        <TextInput source="amount" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export const AumBreakdownCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    notify(aumFailureMessage(error), "warning");
  };
  return (
    <Create onFailure={onFailure} {...props}>
      <SimpleForm>
        <TextInput source="company_name" validate={required()} />
        <TextInput source="amount" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
