import { defaultTheme } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import merge from "lodash/merge";

export const theme = createMuiTheme(
  merge({}, defaultTheme, {
    palette: {
      secondary: {
        main: "#27829E",
      },
    },
  }),
);
