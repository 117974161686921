import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DateField,
  DateTimeInput,
  ArrayInput,
  SimpleFormIterator,
  Filter,
  SelectInput,
  required,
} from "react-admin";
import { exportSanitizedData } from "../utils/csvSanitizedExport";
import { editApiUrl } from "../config";

const exporter = (rows) => {
  const rowsForExport = rows.map((post) => {
    const { id, deleted, all_day, date_deleted, ...rowsForExport } = post;
    return rowsForExport;
  });
  exportSanitizedData(
    rowsForExport,
    ["title", "start", "end", "desc", "attendees", "host"],
    "iln-calendar",
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const CalendarList = (props) => (
  <List {...props} exporter={exporter} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <DateField source="start" />
      <DateField source="end" />
      <TextField source="desc" />
      <TextField source="attendees" />
      <TextField source="host" />
      <TextField source="category.category_name" label="Category" />
    </Datagrid>
  </List>
);

export const CalendarEdit = (props) => {
  const token = JSON.parse(localStorage.getItem("iln_admin_access_token"));
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    fetch(editApiUrl() + `/iln-calendar-categories/`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        let arr = [];
        for (var i in response) {
          const obj = {
            id: response[i]["id"],
            name: response[i]["category_name"],
          };
          arr.push(obj);
        }
        setCategories(arr);
      })
      .catch((error) => console.log(error));
  }, [token]);

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="title" validate={required()} />
        <DateTimeInput source="start" validate={required()} />
        <DateTimeInput source="end" validate={required()} />
        <TextInput multiline source="desc" validate={required()} />
        <ArrayInput source="attendees" validate={required()}>
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="host" validate={required()} />
        <SelectInput
          source="category.id"
          choices={categories}
          label="Category"
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
};

export const CalendarCreate = (props) => {
  const token = JSON.parse(localStorage.getItem("iln_admin_access_token"));
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    fetch(editApiUrl() + `/iln-calendar-categories/`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        let arr = [];
        for (var i in response) {
          const obj = {
            id: response[i]["id"],
            name: response[i]["category_name"],
          };
          arr.push(obj);
        }
        setCategories(arr);
      })
      .catch((error) => console.log(error));
  }, [token]);

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" validate={required()} />
        <DateTimeInput source="start" validate={required()} />
        <DateTimeInput source="end" validate={required()} />
        <TextInput multiline source="desc" validate={required()} />
        <ArrayInput source="attendees" validate={required()}>
          <SimpleFormIterator>
            <TextInput />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="host" validate={required()} />
        <SelectInput
          source="category.id"
          choices={categories}
          label="Category"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
