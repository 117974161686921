import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

const sanitizeCellValue = (value) => {
  if (typeof value !== "string") {
    return value;
  }

  const specialPrefixes = ["=", "+", "-", "@"];
  const hasSpecialPrefix = specialPrefixes.some((prefix) =>
    value.startsWith(prefix),
  );

  return hasSpecialPrefix ? `'${value}` : value;
};

const sanitizeData = (data, headers) => {
  return data.map((row) => {
    const sanitizedRow = {};

    headers.forEach((header) => {
      if (row.hasOwnProperty(header)) {
        sanitizedRow[header] = sanitizeCellValue(row[header]);
      }
    });

    return sanitizedRow;
  });
};

export const exportSanitizedData = (rowsForExport, headers, fileName) => {
  const sanitizedData = sanitizeData(rowsForExport, headers);

  jsonExport(sanitizedData, { headers }, (err, csv) => {
    if (err) return console.error(err);

    downloadCSV(csv, fileName);
  });
};
