import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  Filter,
  required,
} from "react-admin";
import { exportSanitizedData } from "../utils/csvSanitizedExport";

const sustainableGoals = [
  { id: "No Poverty", name: "No Poverty" },
  { id: "Zero Hunger", name: "Zero Hunger" },
  { id: "Good Health and Well-Being", name: "Good Health and Well-Being" },
  { id: "Quality Education", name: "Quality Education" },
  { id: "Gender Equality", name: "Gender Equality" },
  { id: "Clean Water and Sanitation", name: "Clean Water and Sanitation" },
  { id: "Affordable and Clean Energy", name: "Affordable and Clean Energy" },
  {
    id: "Decent Work and Economic Growth",
    name: "Decent Work and Economic Growth",
  },
  {
    id: "Industry, Innovation and Infrastructure",
    name: "Industry, Innovation, and Infrastructure",
  },
  { id: "Reduced Inequalities", name: "Reduced Inequalities" },
  {
    id: "Sustainable Cities and Communites",
    name: "Sustainable Cities and Communites",
  },
  {
    id: "Responsible Consumption and Production",
    name: "Responsible Consumption and Production",
  },
  { id: "Climate Action", name: "Climate Action" },
  { id: "Life Below Water", name: "Life Below Water" },
  { id: "Life on Land", name: "Life on Land" },
  {
    id: "Peace, Justice and Strong Institutions",
    name: "Peace, Justice, and Strong Institutions",
  },
  { id: "Partnerships for the Goals", name: "Partnerships for the Goals" },
];

const themes = [
  { id: "diversity", name: "diversity" },
  { id: "climate change", name: "climate change" },
  { id: "sustainable infrastructure", name: "sustainable infrastructure" },
  { id: "communication", name: "communication" },
];

const exporter = (rows) => {
  const rowsForExport = rows.map((post) => {
    const { id, deleted, date_deleted, ...rowsForExport } = post;
    return rowsForExport;
  });
  exportSanitizedData(
    rowsForExport,
    [
      "name",
      "co_facilitator",
      "theme",
      "members",
      "partners",
      "goals",
      "description",
      "kpi",
    ],
    "iln-initiatives",
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const InitiativeList = (props) => (
  <List {...props} exporter={exporter} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="co_facilitator" />
      <TextField source="theme" />
      <TextField source="members" />
      <TextField source="partners" />
      <TextField source="goals" />
      <TextField multiline source="description" />
      <TextField source="kpi" />
    </Datagrid>
  </List>
);

export const InitiativeEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <ArrayInput source="co_facilitator" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        source="theme"
        choices={themes}
        label="Initiative"
        validate={required()}
      />
      <ArrayInput source="members" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="partners" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="kpi" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <div>
        <p>Based on the UN's 17 Sustainable Development Goals</p>
        <SelectInput
          source="goals"
          choices={sustainableGoals}
          validate={required()}
        />
      </div>
      <TextInput multiline source="description" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const InitiativeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <ArrayInput source="co_facilitator" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <SelectInput
        source="theme"
        choices={themes}
        label="Initiative"
        validate={required()}
      />
      <ArrayInput source="members" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="partners" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="kpi" validate={required()}>
        <SimpleFormIterator>
          <TextInput />
        </SimpleFormIterator>
      </ArrayInput>
      <div>
        <p>Based on the UN's 17 Sustainable Development Goals</p>
        <SelectInput
          source="goals"
          choices={sustainableGoals}
          validate={required()}
        />
      </div>
      <TextInput multiline source="description" validate={required()} />
    </SimpleForm>
  </Create>
);
