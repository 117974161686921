import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DateInput,
  SelectInput,
  Filter,
  required,
} from "react-admin";
import { editApiUrl } from "../config";
import { exportSanitizedData } from "../utils/csvSanitizedExport";

const exporter = (rows) => {
  const rowsForExport = rows.map((post) => {
    const { id, deleted, date_deleted, ...rowsForExport } = post;
    return rowsForExport;
  });
  exportSanitizedData(
    rowsForExport,
    [
      "name",
      "milestone_type",
      "start_date",
      "end_date",
      "responsible",
      "initiative_milestone",
      "milestone_parent",
    ],
    "iln-milestones",
  );
};

const SearchFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="search" alwaysOn />
  </Filter>
);

export const MilestoneList = (props) => (
  <List {...props} exporter={exporter} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="milestone_type" />
      <TextField source="start_date" />
      <TextField source="end_date" />
      <TextField source="responsible" />
      <TextField source="initiative_milestone" />
      <TextField source="milestone_parent" />
    </Datagrid>
  </List>
);

export const MilestoneEdit = (props) => {
  const token = JSON.parse(localStorage.getItem("iln_admin_access_token"));
  const [programs, setProgramList] = React.useState([]);
  React.useEffect(() => {
    fetch(editApiUrl() + `/initiative/`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        let programs = [];
        for (var i in response) {
          const obj = {
            id: response[i]["name"],
            name: response[i]["name"],
          };
          programs.push(obj);
        }
        setProgramList(programs);
      })
      .catch((error) => console.log(error));
  }, [token]);
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="milestone_type" validate={required()} />
        <DateInput source="start_date" validate={required()} />
        <DateInput source="end_date" validate={required()} />
        <TextInput source="responsible" validate={required()} />
        <SelectInput
          source="initiative_milestone"
          choices={programs}
          validate={required()}
        />
        <div>
          <p>
            Used to group milestones together. For example, writing "Industry
            Day" will group all milestones with "Industry Day" set as the
            Milestone Parent together. Leave blank if the Milestone doesn't need
            to be grouped together.
          </p>
          <TextInput source="milestone_parent" />
        </div>
      </SimpleForm>
    </Edit>
  );
};

export const MilestoneCreate = (props) => {
  const token = JSON.parse(localStorage.getItem("iln_admin_access_token"));
  const [programs, setProgramList] = React.useState([]);
  React.useEffect(() => {
    fetch(editApiUrl() + `/initiative`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: `JWT ${token}`,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        let programs = [];
        for (var i in response) {
          const obj = {
            id: response[i]["name"],
            name: response[i]["name"],
          };
          programs.push(obj);
        }
        setProgramList(programs);
      })
      .catch((error) => console.log(error));
  }, [token]);
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="milestone_type" validate={required()} />
        <DateInput source="start_date" validate={required()} />
        <DateInput source="end_date" validate={required()} />
        <TextInput source="responsible" validate={required()} />
        <SelectInput
          source="initiative_milestone"
          choices={programs}
          validate={required()}
        />
        <div>
          <p>
            Used to group milestones together. For example, writing "Industry
            Day" will group all milestones with "Industry Day" set as the
            Milestone Parent together. Leave blank if the Milestone doesn't need
            to be grouped together.
          </p>
          <TextInput source="milestone_parent" />
        </div>
      </SimpleForm>
    </Create>
  );
};
