export const onFailureMessage = (error) => {
  const key = Object.keys(error.message)[0];
  let errorMessage = `${key} - ${error.message[key][0]}`;
  if (key === "non_field_errors") {
    errorMessage = `${error.message[key][0]}`;
  }
  return errorMessage;
};

export const aumFailureMessage = (error) => {
  const key = Object.keys(error.body)[0];
  const message = error.body[key][0];
  return `${key} - ${message}`;
};
